<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :customFiltersShow="true"
          :zoneFilterShow="zoneAgencyFilter"
          :agencyFilterShow="zoneAgencyFilter"
          :locationFilterShow="true"
          :roleFilterShow="true"
          :processFilterShow="true"
          :workerFilterShow="true"
          :dateRangeFilterShow="true"
          :locationDefaultValue="true"
          :supervisorFilterShow="true"
          supervisorFilterPlaceholder="observer_filter"
          @changeFilter="updateFilter"
        )

  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        )
        
  div(v-else)
    b-row(v-if="!confirmations_total")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_confirmations_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-total(:statistic="behaviours_total")
        b-col(sm="3")
          statistic-card-vertical-index(:statistic="adherence_index")
        b-col(sm="3")
          statistic-card-vertical-confirmations(:statistic="confirmations_total")

      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.behaviour') }}
              th {{ $t('message.tableHeader.activity') }}
              th {{ $t('message.tableHeader.process') }}
              th {{ $t('message.tableHeader.score') }}
              th {{ $t('message.tableHeader.total') }}
              th {{ $t('message.tableHeader.adherence') }}
            b-tr(v-for="(behaviour, index) in behaviours", :key="index")
              b-td(v-html="behaviour.behaviour", class="behaviourClass")
              b-td {{ behaviour.activity }}
              b-td {{ behaviour.process }}
              b-td {{ behaviour.score }}
              b-td {{ behaviour.total }}
              b-td {{ behaviour.adherenceText }}
      b-button(:disabled="!behaviours.length")
        json-excel(:data="behaviours", :fields="excelFields")
          | {{ $t('download_xlsx') }}
</template>

<script>
import { onMounted, ref } from "@vue/composition-api/dist/vue-composition-api"
import { BTable, BTableSimple, BThead, BTr, BTd, BCard } from "bootstrap-vue"
import JsonExcel from "vue-json-excel"
import StatisticCardVerticalTotal from './charts/StatisticCardVerticalTotal.vue'
import StatisticCardVerticalIndex from './charts/StatisticCardVerticalIndex.vue'
import StatisticCardVerticalConfirmations from './charts/StatisticCardVerticalConfirmations.vue'
import Filters from "@/views/organization/Filters.vue"
import i18n from '@/libs/i18n'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import { useRouter } from '@core/utils/utils'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import store from "@/store"
import useCommon from '@/views/organization/useCommon'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    JsonExcel,
    StatisticCardVerticalTotal,
    StatisticCardVerticalIndex,
    StatisticCardVerticalConfirmations,
    Filters,
    ViewButton,
    Loading,
  },
  setup() {
    const { handleError } = useCommon()
    const { getItemsWithAggregate, ObjectId, getItems } = realmConnection()
    const userData = store.state?.userStore?.userData;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const behaviours = ref([]);
    const behaviours_total = ref(0);
    const confirmations_total = ref(0);
    const adherence_index = ref("-");
    const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : []);
    const roleFilter = ref([]);
    const processFilter = ref([]);
    const workerFilter = ref([]);
    const supervisorFilter = ref([]);
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const processChange = ref(false)
    const isLoading = ref(true)
    const zoneAgencyFilter = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).zone_agency_filter
      : false
    const excelFields = ref({
      [i18n.t('message.tableHeader.behaviour')]: {
        field: "behaviour",
        callback: (value) => {
          const behaviourFormatted = (((value.replaceAll('</p><p>', '<br>')).replaceAll('</p><ul><li>', '<br>')).replaceAll('</li><li>', '<br>')).replaceAll('</li></ul>', '<br>')
          return behaviourFormatted
        },
      },
      [i18n.t('message.tableHeader.activity')]: "activity",
      [i18n.t('message.tableHeader.process')]: "process",
      [i18n.t('message.tableHeader.score')]: "score",
      [i18n.t('message.tableHeader.total')]: "total",
      [i18n.t('message.tableHeader.adherence')]: "adherence",
    })
    const { router } = useRouter()
    const { getDatesFromRange } = useCommonDashboards()

    async function getProcesses() {
      isLoading.value = true

      if (!processChange.value && behaviours_total.value > 0) {
        for (const behaviour of behaviours.value) {
          behaviour.score = "-",
          behaviour.total = "-",
          behaviour.adherence = "-"
          behaviour.adherenceText = "-"
        }
        adherence_index.value = "-"
        getConfirmations()
      } else {
        processChange.value = false

        const query = {
          client_id: ObjectId(clientId),
          deleted: { $ne: true }
        }

        if (processFilter.value.length > 0) {
          query._id = { $in: processFilter.value.map(e => ObjectId(e)) }
        }

        try {
          const items = await getItems({ collection: 'process', query })

          parseProcessData(items)
          getConfirmations()
        } catch (error) {
          console.log(error);
          handleError({ error, defaultMessage: i18n.t('message.err_behaviours_list') })
          isLoading.value = false
        }
      }
    }

    // Data for adherence XLSX File
    function parseProcessData(items) {

      let behaviours_data = []

      for (const process of items) {
        for (const activity of process.activities) {
          for (const [index, behaviour] of activity.behaviours.entries()) {
            if (behaviour.type === "bool" || behaviour.type === "rating") {
              behaviours_data.push({
                behaviour: behaviour.name,
                activity: activity.name,
                process: process.name,
                score: "-",
                total: "-",
                adherence: "-",
                adherenceText: "-",
                index
              });
            }
          }
        }
      }
      behaviours.value = behaviours_data
      behaviours_total.value = behaviours_data.length
    }

    async function getConfirmations() {
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      try {
        const initialQuery = {
          client_id: ObjectId(clientId),
          date: { $gte: startFilter, $lt: endFilter },
          pending: { $ne: true }
        }

        if (processFilter.value.length > 0) {
          initialQuery.process = { $in: processFilter.value.map(e => ObjectId(e)) }
        }

        if (workerFilter.value.length > 0) {
          initialQuery.worker = { $in: workerFilter.value.map(e => ObjectId(e)) }
        }

        if (supervisorFilter.value.length > 0) {
          initialQuery.supervisor = { $in: supervisorFilter.value.map(e => ObjectId(e)) }
        }

        const finalQuery = {}

        if (locationFilter.value.length > 0) {
          finalQuery['worker.locations._id'] = { $in: locationFilter.value }
        }

        if (zoneFilter.value.length > 0) {
          finalQuery['worker.locations.zone'] = { $in: zoneFilter.value }
        }

        if (agencyFilter.value.length > 0) {
          finalQuery['worker.locations.agency'] = { $in: agencyFilter.value }
        }

        if (roleFilter.value.length > 0) {
          finalQuery['worker.roles'] = { $in: roleFilter.value.map(e => ObjectId(e)) }
        }

        const pipeline = [
          { $match: initialQuery },
          {
            $lookup: {
              from: 'worker',
              localField: 'worker',
              foreignField: '_id',
              pipeline: [
                { $project: { name: 1, locations: 1, roles: 1 } },
                {
                  $lookup: {
                    from: 'location',
                    localField: 'locations',
                    foreignField: '_id',
                    pipeline: [ { $project: { location: 1, zone: 1, agency: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                    as: 'locations',
                  },
                },
                { $addFields: { _id: { $toString: "$_id" } } }
              ],
              as: 'worker'
            }
          },
          { $match: finalQuery },
          {
            $lookup: {
              from: 'worker',
              localField: 'supervisor',
              foreignField: '_id',
              pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'supervisor'
            }
          },
          {
            $lookup: {
              from: 'process',
              localField: 'process',
              foreignField: '_id',
              pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'process'
            }
          },
          { $addFields: { _id: { $toString: "$_id" }, worker: { $arrayElemAt: ["$worker", 0] }, supervisor: { $arrayElemAt: ["$supervisor", 0] }, process: { $arrayElemAt: ["$process", 0] } } },
        ]
      
        const items = await getItemsWithAggregate({ collection: 'confirmation', pipeline })

        parseConfirmationData(items)
      } catch (error) {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
      } finally {
        isLoading.value = false
      }
    }

    function parseConfirmationData(items) {
      confirmations_total.value = 0
      let adherence_data = {score: 0, total: 0}

      for (const confirmation of items) {
        confirmations_total.value++
        for (const activity of confirmation.activities) {
          for (const [index, behaviour] of activity.behaviours.entries()) {
            if ((behaviour.type === "bool" || behaviour.type === "rating") && behaviour.answer && behaviour.answer !== "unknown" && behaviour.answer !== "N/A") {
              for (const b of behaviours.value) {
                if (confirmation.process?.name === b.process && activity.name === b.activity && index === b.index) {
                  if (behaviour.type === "bool") {
                    if (behaviour.answer === "true") {
                      b.score = b.score === "-" ? 1 : b.score + 1
                      adherence_data.score += 1
                    }
                    if (behaviour.answer === "true" || behaviour.answer === "false") {
                      if (b.score === "-") b.score = 0
                      b.total = b.total === "-" ? 1 : b.total + 1
                      adherence_data.total += 1
                    }
                  }
                  else {
                    b.score = b.score === "-" ? parseInt(behaviour.answer) : b.score + parseInt(behaviour.answer)
                    b.total = b.total === "-" ? 5 : b.total + 5
                    adherence_data.score += parseInt(behaviour.answer)
                    adherence_data.total += 5
                  }
                  if (b.total !== "-") {
                    b.adherence = behaviourAdherence(b.score, b.total)
                    b.adherenceText = `${b.adherence}%`
                  }
                }
              }
            }
          }
        }
      }
      behaviours.value.sort(function (a, b) {
        if (b.score === 0 && a.score === 0) return b.total - a.total
        if (b.adherence === "-" && a.adherence !== "-") return -1
        return a.adherence - b.adherence
      })
      adherence_index.value = adherence_data.total === 0
        ? "-"
        : `${behaviourAdherence(adherence_data.score, adherence_data.total)}%`
    }

    function behaviourAdherence(score, total) {
      return Math.round(score / total * 1000) / 10
    }

    function updateFilter(data) {
      if (processFilter.value !== data.processFilter) processChange.value = true

      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      processFilter.value = data.processFilter;
      workerFilter.value = data.workerFilter;
      supervisorFilter.value = data.supervisorFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      getProcesses();
    }

    onMounted(getProcesses);

    return {
      updateFilter,
      behaviours,
      behaviours_total,
      confirmations_total,
      adherence_index,
      excelFields,
      router,
      isLoading,
      zoneAgencyFilter,
      colors
    };
  },
};
</script>

<style scoped>
  .behaviourClass ::v-deep p {
    margin: 0px;
  }
</style>